<template>
    <div class="application-detail" v-if="pageData">
        <div class="page-header">
            <el-row>
                <el-col :span="24">
                    <div class="header-desc">
                        <router-link to><el-button icon="el-icon-arrow-left" round @click="$router.back(-1)">{{ $t('data.public.back') }}</el-button></router-link>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20" class="mb-20">
            <el-col :span="24">
                <h2 align="center" class="mb-30" style="font-size: 30px;">{{ $t('data.applicationDetail.title') }}</h2>
            </el-col>
            <el-col :span="8" :offset="2">
                <div class="application-text">
                    <!-- <p>{{ $t('data.applicationDetail.topic') }}: {{pageData.topic}}</p>
                    <p>{{ $t('data.applicationDetail.group') }}: {{pageData.group.name}}</p>
                    <p>{{ $t('data.applicationDetail.eCertNo') }}: {{pageData.number}}</p>
                    <p>{{ $t('data.applicationDetail.timeRequested') }}: {{pageData.created_at}}</p>
                    <p>{{ $t('data.applicationDetail.approvalTime') }}: {{pageData.issue_date}}</p> -->
                    <div class="text-flex">
                        <div class="name-left">
                            <p>{{ $t('data.applicationDetail.topic') }}: </p>
                            <p>{{ $t('data.applicationDetail.group') }}: </p>
                            <p>{{ $t('data.applicationDetail.eCertNo') }}: </p>
                            <p>{{ $t('data.applicationDetail.timeRequested') }}: </p>
                            <p>{{ $t('data.applicationDetail.approvalTime') }}: </p>
                        </div>
                        <div class="val-right">
                            <p>{{pageData.topic}}</p>
                            <p>{{pageData.group.name}}</p>
                            <p>{{pageData.number}}</p>
                            <p>{{pageData.created_at}}</p>
                            <p>{{pageData.issue_date}}</p>
                        </div>
                    </div>


                    <el-select v-model="pageData.status" :placeholder="$t('data.status.status')" class="mt-20" size="small" @change="certStatus">
                        <el-option :label="$t('data.status.valid')" :value="1"></el-option>
                        <el-option :label="$t('data.status.expired')" :value="2"></el-option>
                        <el-option :label="$t('data.status.cancel')" :value="3"></el-option>
                        <el-option :label="$t('data.status.change')" :value="4"></el-option>
                        <el-option :label="$t('data.status.amended')" :value="5"></el-option>
                        <el-option :label="$t('data.status.remark')" :value="6"></el-option>
                    </el-select>
                    
                    <p style="font-size: 12px;">{{ $t('data.public.selectExpiredInfo') }}</p>

                    <div class="mt-20">
                        <router-link :to="'/ecert/applicant-detail/'+pageData.id"><el-button type="primary" size="small">{{ $t('data.applicationDetail.previewEcert') }}</el-button></router-link>
                    </div>
                    <div class="mt-20">
                        <el-upload
                            class="upload-demo"
                            action="#"
                            :on-change="handleChange"
                            :show-file-list=false
                            :auto-upload="false"
                            >
                            <el-button type="primary" size="small">{{ $t('data.applicationDetail.uploadDocument') }}</el-button>
                        </el-upload>
                        <a v-if="uploadDocumentInfo" :href="uploadDocumentInfo.url" class="text-upload" target="view_window">{{uploadDocumentInfo.original_name}}</a>
                    </div>


                </div>
            </el-col>

            <el-col :span="12">
                <el-card shadow="always">
                    <div class="user-photo">
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="pageData.member.avatar"
                            fit="cover"></el-image>
                        <h3>{{ $t('data.applicationDetail.applicantId') }}: {{pageData.member.id}}</h3>
                    </div>
                    <!-- <div class="application-text">
                        <p>{{ $t('data.applicationDetail.informationClaimed') }}:</p>
                        <p>{{ $t('data.applicationDetail.firstName') }}:{{pageData.member.name}}</p>
                        <p>{{ $t('data.applicationDetail.lastName') }}:</p>
                        <p>{{ $t('data.applicationDetail.namePrinted') }}</p>
                        <p>{{ $t('data.applicationDetail.email') }}: {{pageData.member.email}}</p>
                        <p>{{ $t('data.applicationDetail.mobile') }}: {{pageData.member.mobile}}</p>
                        <p>{{ $t('data.applicationDetail.sex') }}: {{pageData.member.sex}}</p>
                        <p>{{ $t('data.applicationDetail.nationality') }}: {{pageData.member.country.name}}</p>
                        <p>{{ $t('data.applicationDetail.dateBirth') }}: XX:XX:XX</p>
                        <p>{{ $t('data.applicationDetail.address') }}:</p>
                        <p>{{pageData.member.addresses}}</p>
                    </div> -->

                    <div class="mb-20 application-text text-flex" style="justify-content: center;">
                        <div class="name-left">
                            <p>{{ $t('data.applicationDetail.informationClaimed') }}:</p>
                            <p>{{ $t('data.applicationDetail.firstName') }}:</p>
                            <p>{{ $t('data.applicationDetail.lastName') }}:</p>
                            <p>{{ $t('data.applicationDetail.namePrinted') }}:</p>
                            <p>{{ $t('data.applicationDetail.email') }}:</p>
                            <p>{{ $t('data.applicationDetail.mobile') }}:</p>
                            <p>{{ $t('data.applicationDetail.sex') }}:</p>
                            <p>{{ $t('data.applicationDetail.nationality') }}:</p>
                            <p>{{ $t('data.applicationDetail.dateBirth') }}:</p>
                            <p>{{ $t('data.applicationDetail.address') }}:</p>
                        </div>
                        <div class="val-right">
                            <p>&nbsp;</p>
                            <p>{{pageData.member.first_name || '&nbsp;'}}</p>
                            <p>{{pageData.member.last_name || '&nbsp;'}}</p>
                            <p>{{pageData.member.name_on_certificate || '&nbsp;'}}</p>
                            <p>{{pageData.member.email || '&nbsp;'}}</p>
                            <p>{{pageData.member.mobile || '&nbsp;'}}</p>
                            <p>
                                <template v-if="pageData.member.sex === 1">
                                    {{ $t('data.public.sex.male') }}
                                </template>
                                <template v-else-if="pageData.member.sex === 2">
                                    {{ $t('data.public.sex.female') }}
                                </template>
                                <template v-else>
                                    &nbsp;
                                </template>
                            </p>
                            <p>{{pageData.member.country.name || '&nbsp;'}}</p>
                            <p>{{pageData.member.birthdaye || '&nbsp;'}}</p>
                            <p>
                                <template v-for="(item,index) in pageData.member.addresses" :key="index">
                                    {{item}}<br>
                                </template>
                            </p>
                        </div>
                    </div>
                </el-card>
            </el-col>

        </el-row>
    </div>    
</template>

<script>
certsDetails
import { certsDetails,certsStatusReport,uploadFile } from '../../api/index'
import { ElMessage } from 'element-plus'
export default {
    name: "Application Detail",
    data() {
        return {
            pageData: null,
            uploadDocumentInfo: {}
        }
    },
    mounted(){
        this.initPageData();
    },
    methods: {
        initPageData(){
            let apiData = {
              id: this.$route.params.id
            }
            certsDetails({apiData}).then( res => {
                this.pageData = res.data;
                this.uploadDocumentInfo = res.data.report
            })
        },
        certStatus(){
            let apiData = {
              id: this.$route.params.id,
              status: this.pageData.status
            }
            certsStatusReport({apiData}).then( res => {
                ElMessage.success({
                    message: 'Success',
                    type: 'success'
                });
            })
        },
        handleChange(files, fileList) {
            let param = new FormData();
            param.append('file', files.raw);
            param.append('type', 'report');
            let apiData = param;
            uploadFile({apiData}).then( res => {
                this.fileUpload(res.data);
            })
        },
        fileUpload(obj){
            let apiData = {
              id: this.$route.params.id,
              report: obj
            }
            certsStatusReport({apiData}).then( res => {
                this.uploadDocumentInfo = obj;
                ElMessage.success({
                    message: 'Success',
                    type: 'success'
                });
            })
        }
    }
}
</script>

<style scoped>
.application-text p{
    color: #868e96;
    line-height: 31px;
    white-space: nowrap;
}
.user-photo{
    text-align: center;
    margin-bottom: 30px;
}
.user-photo .el-image{
    border-radius: 50%;
    margin-bottom: 30px;
}
.text-upload{
    margin-top: 10px;
    display: block;
    font-size: 14px;
    color: #868e96;
}
</style>